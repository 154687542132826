.ce_rsce_popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
    .container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4rem 2rem;
        .container-inner {
            background-color: $white;
            max-width: 550px;
            //max-height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
           
            img {
                width: 100%;
                object-fit: cover;
                //max-height: 200px;
            }
            .text {
                padding: 2rem;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                h3 {
                    margin: 0;
                    font-size: 1.4rem;
                    line-height: 2rem;
                }
            }
            .close {
                position: absolute;
                padding: 1rem;
                top: 0px;
                right: 0px;
                background-image: url('/files/themes/sonnleitner/images/icons/closeWhite.svg');
                background-repeat: no-repeat;
                background-size: cover;
                width: 28px;
                height: 28px;
                transition: transform .5s;
                &:hover {
                    cursor: pointer;
                    transform: rotateZ(-270deg);
                }
            }
        }
    }
}


@mixin rsce_popup_tablet {
    .ce_rsce_popup {
        .container {
            padding: 2rem;
            .container-inner {
                .close {
                    top: -30px;
                    right: -30px;
                }
                img {
                    max-height: unset;
                }
            }
        }
    }
}



@media (max-height: 400px) {
    .ce_rsce_popup {
        .container {
            .container-inner {
                .close {
                    top: -30px;
                    right: -30px;
                }
                img {
                    display: none;
                }
            }
        }
    }
  }